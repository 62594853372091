export default function useApiActions() {
  const { $portalApi } = useNuxtApp()

  const keyId = useId()

  const apiActionsError = useState<Record<string, any> | null>(`api-actions-error-${keyId}`, () => null)
  const apiActions = useState<PortalApiResponseTemp<'get-api-actions'>['actions'] | null>(`api-actions-${keyId}`, () => null)

  const getApiActions = async (apiIdOrSlug: string | null) => {
    try {
      apiActionsError.value = null

      if (apiIdOrSlug) {
        const data = await $portalApi('/api/v3/apis/{apiIdOrSlug}/actions', {
          path: {
            apiIdOrSlug,
          },
        })

        apiActions.value = data?.actions
      }
    } catch (e: any) {
      apiActionsError.value = e
    }
  }

  return {
    getApiActions,
    apiActions,
    apiActionsError,
  }
}
