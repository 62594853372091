export default function useCanRegisterApp() {
  const { getApiActions, apiActions } = useApiActions()
  const { context } = storeToRefs(usePortalStore())
  const { session } = storeToRefs(useSessionStore())

  const keyId = useId()

  const showRegisterButton = useState(`show-register-button-${keyId}`, () => false)

  const canRegister = async (options: { authStrategies: Record<string, any>[], slug: string }): Promise<void> => {
    // hide button for non-authenticated user
    if (!session.value?.authenticated) {
      showRegisterButton.value = false
    } else {

      // hide button if no auth strategies
      if (!options.authStrategies?.length) {
        showRegisterButton.value = false

      // show button if no rbac_enabled
      } else if (!context.value?.rbac_enabled) {
        showRegisterButton.value = true

      // get actions if rbac_enabled === true
      } else {
        await getApiActions(String(options.slug || ''))

        showRegisterButton.value = !!apiActions.value?.register
      }
    }
  }

  return {
    showRegisterButton,
    canRegister,
  }
}
